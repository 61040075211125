import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import moment from "moment";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfContent from "@/vue/domain/content/df-content";

@Component({
  filters: {
    dateFormat(value: Date | string): string {
      return value != null
        ? moment(value, Utils.ISO_8601_STRING_PATTERN).format(
            Utils.DATE_STRING_PATTER
          )
        : "";
    },
  },
})
export default class DfRecipeCardComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Object, default: () => ({}) })
  recipe!: DfContent;

  get recipePreviewStyle(): any {
    const style: any = {};
    const recipePreview = Utils.getPropertyImageUrl(
      this.recipe,
      Utils.PROPERTY_PREVIEW,
      null
    );

    if (recipePreview) {
      style.backgroundImage = `url('${recipePreview}')`;
      style.backgroundPosition = `center center`
    }

    return style;
  }

  get recipeTitle(): string {
    return Utils.getPropertyValue(this.recipe, Utils.PROPERTY_TITLE, "STRING");
  }

  get recipeDate(): string {
    return Utils.getPropertyValue(this.recipe, Utils.PROPERTY_DATE, "DATE");
  }

  private onClick() {
    return this.$emit("recipeCardClick", this.recipe);
  }
}
